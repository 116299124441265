import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[0]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[3]}")` }}
        ></figure>

        <img
          src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(6).png?alt=media&token=89428890-d8bb-4227-8838-dbffb470c309"}
          alt='no found'
          loading='lazy'
          className='absolute -bottom-1 left-0 w-[100%] h-[6%] z-50'
        />
        <div className="z-[4] relative w-4/5 mx-auto pt-[280px] pb-[100px] md:pb-[130px] md:pt-[320px]  ">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white md:text-[50px] text-[30px] text-center">
              {rpdata?.dbSlogan?.[4].slogan}
            </h1>
            <p className="text-white text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent btnStyle={'one'} btnUrl={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} btnName={'Call Us Now'} />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
