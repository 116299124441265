import React from "react";


const Form = (props) => {

  return (
    <div className="py-10 px-2">
      <div className="elfsight-app-84ea86eb-5ec0-413b-912f-a370080e513c"></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
